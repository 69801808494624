import React, { useEffect } from "react";
import Seo from "../components/seo";
import Intro from "../components/intro";
import styled from 'styled-components'

import useLocalStorage from "../hooks/useLocalStorage";
import CarouselHome from "../components/carousel_home";

const Main = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Home = () => {
  const [visited, setVisited] = useLocalStorage("visited", false);

  useEffect(() => {
    if (!visited) {
      const id = setTimeout(() => {
        setVisited("true");
      }, 5000);
      return () => clearTimeout(id);
    }
  }, []);

  // {!visited && <Intro />}

  return (
    <>
      <Seo />
      <Main visited={visited}>
        <CarouselHome />
      </Main>
    </>
  );
};

export default Home;
