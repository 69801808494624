import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const ContainerDesktop = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  @media(max-width: 768px) {
    display: none;
  }
`;

const ContainerMobile = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  @media(max-width: 768px) {
    display: block;
  }
`;

const S = styled.div`
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center center;
  width: 100vw;
  height: 100vh;
`

const CarouselHome = () => {

  const data = useStaticQuery(graphql`
    query {
      sanityHomepage {
        homeslides {
          desktop_image {
            _key
            asset {
              publicUrl
              url
            }
          }
          mobile_image {
            _key
            asset {
              publicUrl
              url
            }
          }
        }
      }
    }
  `)

  const desktop_slides = data.sanityHomepage.homeslides.map((slide) => {
    return slide.desktop_image.asset.url
  })

  const mobile_slides = data.sanityHomepage.homeslides.map((slide) => {
    return slide.mobile_image.asset.url
  })

  return (
    <>
      <ContainerDesktop>
        <Fade duration={1500} transitionDuration={1500} indicators={false} arrows={false} pauseOnHover={false} canSwipe={false}>
          {desktop_slides.map((s, index) => {
            return (
                <div className="each-slide-effect">
                  <S bg={s} />
                </div>
            );
          })}
          </Fade>
      </ContainerDesktop>
      <ContainerMobile>
        <Fade duration={1500} transitionDuration={1500} indicators={false} arrows={false} pauseOnHover={false} canSwipe={false}>
          {mobile_slides.map((s, index) => {
            return (
              <div className="each-slide-effect">
                <S bg={s} />
              </div>
            );
          })}
        </Fade>
      </ContainerMobile>
    </>
  );
};

export default CarouselHome;
